import React, { useEffect, useState } from 'react'
import './App.css'
// @ts-ignore
import Retool from 'react-retool'
import useFubState from './useFubState'
import { useElementSize } from 'usehooks-ts'

function App() {
  //const sample = { name: 'Sample data' }

  const [ref, { height, width }] = useElementSize()

  const { contextObj, fubVerified } = useFubState()
  console.log('contextObj', contextObj)
  console.log('fubVerified', fubVerified)

  console.log('height', height)
  console.log('width', width)

  return (
    <div ref={ref}>
      <Retool
        url={process.env.REACT_APP_RETOOL_URL}
        sandbox
        height={600}
        width={width}
        data={contextObj}
      />
    </div>
  )
}

export default App
